.container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #fff;
}

.LoadingBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 70vh;
}
.heading {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.text {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.image {
  width: 300px;
  margin-bottom: 2rem;
}

.subtext {
  font-size: 1rem;
  color: #888;
}
/* -------headerstyle------ */
.pageHeaderConatiner {
  height: 100px;
  display: flex;
  align-content: space-between;
  flex-direction: column;
  /* align-items: flex-start */
  justify-content: flex-start;
}
.headerTitle {
  font-family: "Montserrat-Bold";
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  color: #0055a6;
  margin-left: 10px;
}
.headerIconBox {
  display: flex;
  align-items: center;
  height: 40%;
  padding-top: 5px;
}
.headerTitleBox {
  background-color: "red";
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.headerButtonBox {
  margin-right: 20px;
}
.headerDiscription {
  font-family: "Montserrat-Light";
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0em;
  padding-left: 40px;
  color: #9ba9b6;
}
.headerBtn {
  width: 167px;
  height: 37px;
  padding: 10px, 16px, 10px, 16px;
  gap: 8px;
}

@media screen and (max-width: 576px) {
  .headerBtn {
    width: 137px;
    font-size: 11px;
  }
  .headerDiscription {
    font-family: "Montserrat-Light";
    font-size: 10px;
  }
  .headerTitle {
    font-family: "Montserrat-Bold";
    font-size: 27px;
  }
  .isDesktop {
    display: none;
  }
}
.ActionModal-box1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
}
:where(.css-dev-only-do-not-override-xs08p8).ant-popover .ant-popover-content {
  position: relative;
  right: 30px !important;
}
.pageHeader-popover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pageHeader-popTxt1 {
  font-family: "Montserrat-Bold";
  font-size: 14px;
  margin-top: 6px;
}
.pageHeader-popTxt2 {
  font-family: "Montserrat-SemiBold";
  font-size: 14px;
  margin-top: 6px;
}
