.webStories-main {
  width: 260px;
  height: 420px;
  padding: 10px;
  position: relative;
}
.webStories-container {
  height: 70vh;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
}
.webStories-Img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;
}
.webStories-title {
  color: rgb(255, 255, 255);
  font-family: "Montserrat-Bold";
  font-size: 12px;
  position: absolute;
  top: 300px;
  padding: 10px;
  text-align: center;
}
.dataList-container {
  height: 70vh;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
}
.dataList-main {
  width: 260px;
  height: 420px;
  padding: 10px;
  position: relative;
}
.dataList-Img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;
}
.dataList-title {
  color: rgb(255, 255, 255);
  font-family: "Montserrat-Bold";
  font-size: 12px;
  position: absolute;
  top: 300px;
  padding: 10px;
  text-align: center;
}
.formLabel {
  font-family: "Montserrat-SemiBold" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #0055a6 !important;
  margin-bottom: 5px !important;
}
.webStoriesForm-imgUpldr {
  width: 100%;
  height: 200px;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  position: relative;
  z-index: 10;
}
.inputFile {
  height: 100%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.inputFile2 {
  height: 100%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.webstories-imgTxt {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  font-weight: 700;
  color: Grey;
}
.webStories-imgurl {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.webStories-img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: cover;
}
.webStorisFrm-moreImg {
  max-width: 160px;
  height: 120px;
  background-color: #eeeeee;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.webStorisFrm-AddedImg {
  width: 100px;
  height: 100px;
  padding: 10px;
  position: relative;
}
.webStories-addedImgMain {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.webStories-delete {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  margin-top: 10px;
  border-radius: 6px 0px 100% 0px;
  background-color: red;
  padding: 2px;
}
.webStories-publish {
  width: 100%;
  height: 40px;
}
