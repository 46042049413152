body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url(../src/assets/font/Montserrat-Bold.ttf) format("opentype");
}
@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat-Light"),
    url(../src/assets/font/Montserrat-Light.ttf) format("opentype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url(../src//assets/font/Montserrat-Medium.ttf) format("opentype");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url(../src/assets/font/Montserrat-Regular.ttf) format("opentype");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"),
    url(../src/assets/font/Montserrat-SemiBold.ttf) format("opentype");
}

@font-face {
  font-family: "Noto_Sans_Medium_500";
  src: url(../src/assets/font/NotoSansMalayalam-Medium.ttf);
}
@font-face {
  font-family: "Noto_Sans_SemiBold_600";
  src: url(../src/assets/font/NotoSansMalayalam-SemiBold.ttf);
}
@font-face {
  font-family: "Noto_Sans_Bold_700";
  src: url(../src/assets/font/NotoSansMalayalam-Bold.ttf);
}
.newsFormLabels {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #0055a6;
  margin-top: 5px;
  margin-bottom: 3px;
}
.expandForm {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: #0055a6;
  font-size: 12px;
}

.tox-promotion {
  display: none;
}
