.filterText {
  font-family: "Montserrat-Medium";
  font-size: 13px;
  line-height: 16px;
  color: "red";
}
.podcastList-card {
  width: 100%;
  background-color: yellowgreen;
  display: flex;
  padding: 20px;
}
.podcastList-cardList {
  width: 200px;
  height: 200px;
  background-color: red;
}
.podcastList-editBtn-yes {
  width: 70px;
  height: 30px;
}
.podcastList-editBtn-yes:hover {
  transform: scale(0.9);
}
.podcastList-editBtn-no:hover {
  transform: scale(0.9);
}

.podcastList-editBtn-no {
  background-color: rgb(245, 245, 245);
  width: 70px;
  height: 30px;
}
.podcastList-CardBox2 {
  margin: 10px;
  width: 300px;
  margin-top: 50px;
}
.podcastList-cardBox1 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.podcastList-main {
  display: flex;
  height: 90vh;
  overflow-x: scroll;
  overflow-y: hidden;
}

.podcastList-ContentBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.formLabel {
  font-family: "Montserrat-SemiBold" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #0055a6 !important;
  margin-bottom: 5px !important;
}
.podcast-ImageUploader {
  width: 378px;
  min-height: 200px;
  display: grid;
  place-items: center;
  border-radius: 8px;
  background: #f0f2f5;
  margin-top: 10px;
  padding: 20px;
}
.podacast-Uploadtxt {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  font-weight: 700;
  color: Grey;
}
.podcast-img {
  max-width: 280px;
  border-radius: 17px;
}
.podcast-Btn {
  width: 378px;
  height: 52px;
}
.podcasrtCard-TileTxt {
}
