.homeScrn-pickerIcon {
  cursor: pointer;
  width: 100%;
  height: 200px;
  border-radius: 6px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

/* handle */
::-webkit-scrollbar {
  display: none;
  width: 1px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c8c6c6;
}

::-webkit-scrollbar-thumb:hover {
  background: #afaeae;
}
.homeScrn-Uploadtxt {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  font-weight: 700;
  color: Grey;
  margin-top: 12px;
}
.hmScrn-SaveEditBtn {
  width: 100%;
  height: 40px;
}
.square-radio-button .ant-radio-inner {
  border-radius: 0;
}

.square-radio-button .ant-radio-inner::after {
  border-radius: 0;
}
.ant-radio-wrapper {
  margin-bottom: 12px;
}
.tags {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  height: 28px;
  background: #f3f3f3;
  border-radius: 4px;
  border: none;
}

/* Styles by Jis */
.NewsImagePickerItem {
  width: 100%;
  height: 200px;
  padding: 5px;
}

.NewsImageBox {
  width: 100%;
  height: 200px;
  border-radius: 7px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  object-fit: cover;
}

.NewsImageBox-img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: relative;
}

.image-delete-btn {
  position: absolute;
  z-index: 999;
  color: #ffffff;
  top: -8px;
  right: -8px;
  cursor: pointer;
  background-color: red;
  border-radius: 100%;
  padding: 3px;
}
.SocialShare-box1 {
  display: flex;
  flex-direction: column;
  border: 1px solid #f2f2f2;
}
.SocialShare-box2 {
  background-color: #f2f3f5;
}
.SocialShare-box3 {
  padding: 15px;
}
.SocialShare-img1 {
  width: 100%;
  aspect-ratio: 1/0.6;
}
.SocialShare-txt1 {
  color: #999ea4;
}
.SocialShare-txt2 {
  color: #000000;
  font-family: "Montserrat-Bold";
}
.PermaBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
}
.RxClipboardCopy {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 5px;
  padding-right: 3px;
}

.homescreen-pageBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(221, 221, 221);
}

.homescreen-pageBox2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
}

.homescreen-pageItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.newsFormBox {
  border-left: 1px solid rgb(222, 222, 222);
  height: 100%;
  padding-left: 20px;
}

.homescreen-table-NoImg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 6px;
  height: 50px;
  width: 70px;
  font-size: 8px;
}
.Analytics-box1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.homescreen-loading-indicator {
  font-size: 16px;
  color: #1e88e5;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  font-family: "Montserrat-Medium";
}
