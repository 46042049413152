.ElectionItem-imageBox1 {
    background-color: rgb(231, 231, 231);
    border-radius: 4px;
    padding: 10px;
}

.ElectionItem-image1 {
    width: 100%;
    object-fit: contain;
    /* aspect-ratio: 1.5 / 1;  */
    border-bottom: 1px solid rgb(195, 195, 195);
    padding-bottom: 10px;
    margin-bottom: 10px;
}
