.homeScrn-pickerIcon-vidyaPrabhaadam {
  cursor: pointer;
  width: 100%;
  height: 190px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
}
.Vidya-table-NoImg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 6px;
  height: 50px;
  width: 70px;
  font-size: 8px;
}
.vidyaImageBox {
  width: 100%;
  height: 150px;
  border-radius: 7px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  object-fit: cover;
}
