.routeBox {
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 0px;
  height: 92vh;
  overflow: scroll;
  overflow-x: hidden;
  background-color: #fff;
}
.Admin-Header {
  height: 8vh;
  border-bottom: 1px solid rgb(217, 217, 217);
  background-color: #f0f2f5;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Admin-Headertxt1 {
  font-family: "Montserrat-SemiBold";
  font-size: 20px;
  color: #0055a6;
}

.Admin-HeaderBox {
  display: flex;
  align-items: center;
  text-align: right;
  cursor: pointer;
  border-left: 1px solid rgb(219, 219, 219);
  padding-left: 20px;
}

.Admin-HeaderTime {
  background-color: #b7d5ee;
  padding: 5px 10px;
  margin-right: 20px;
  width: 120px;
  border-radius: 5px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0055a6;
  font-size: 12px;
}

.Sider-logoBox {
  height: 50px;
  border-bottom: 1px solid rgb(219, 219, 219);
  border-right: 1px solid rgb(219, 219, 219);
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}

.Sider-logo {
  width: 100%;
  height: 40px;
  object-fit: contain;
}

.menuItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 15px 0px;
  margin: 2px 10px;
  font-size: 11px;
  font-family: "Montserrat-SemiBold";
  border-bottom: 1px solid rgb(236, 236, 236);
  text-align: center;
}
.menuItem:hover {
  background-color: #e0e6eb;
  border-radius: 10px;
  border-bottom: 0px solid #e0e6eb;
  color: #0055a6;
}

.active {
  background-color: #e0e6eb;
  border-radius: 10px;
  border-bottom: 0px solid #e0e6eb;
  color: #0055a6;
}

.MobileDrawer-logo-box {
  width: 50px;
  height: 50px;
}
.MobileDrawer-logo-image {
  width: 50px;
  height: 50px;
}
.headerDiscriptioncanvas {
  font-family: "Montserrat-Regular";
  font-size: 13px;
  color: grey;
}
.username {
  font-size: 12px;
}
.popovercontent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  margin: 10px;
}

.Admin-QuickBtn {
  margin-left: 20px;
  border: 1px solid #0055a6;
  padding: 2px 20px;
  border-radius: 6px;
  color: #fff;
  background-color: #0055a6;
}

.Admin-HeaderTxt1 {
  font-family: "Montserrat-SemiBold";
  font-size: 18px;
}

.Admin-HeaderItem {
  margin-bottom: 15px;
  font-family: "Montserrat-SemiBold";
  padding-right: 20px;
}
.Admin-HeaderItem:hover {
  color: #0055a6;
  cursor: pointer;
}
.Admin-HeaderItem:last-child {
  margin-bottom: 0px;
}

.FixedFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #fff;
  width: 93vw;
  margin-left: 7vw;
  border-top: 1px solid rgb(231, 231, 231);
  box-shadow: 0 -1px 5px 2px rgba(0, 0, 0, 0.064);
  padding: 15px 15px;
  height: 9vh;
}

.table-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
