.Epaper-previewBox {
  border: 1px solid grey;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Epaper-ThumbnailPicker {
  min-height: 50vh;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Epaper-ThumbnailImage {
  height: 49vh;
  width: 100%;
  object-fit: contain;
}

.Epaper-PagesList {
  border: 1px solid rgb(224, 224, 224);
  padding: 10px;
  border-radius: 4px;
}

.Epaper-PagesListBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 12px;
  height: 47vh;
  color: gray;
}

.Epaper-pageItem {
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(219, 219, 219);
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
}

.Epaper-ThumbnailPickerBox {
  width: 100%;
}

.Epaper-cropItem {
  display: flex;
  flex-direction: row;
  gap: 10px;
  border-bottom: 1px solid rgb(224, 224, 224);
  padding-bottom: 10px;
  margin-bottom: 15px;
}
