.banner-ImageUpldCard {
  min-width: 460px;
  height: 250px;
  border-radius: 7px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  /* z-index: 100; */
}
.banner-imageuploder {
  height: 90px;
}
.banner-imageupldrTxt {
  color: #919191;
  margin-top: 10px;
  font-family: "Montserrat-SemiBold";
}

.banner-imageupldbox {
  width: 100%;
  height: 192px;
  border-radius: 7px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
}
.banner-imageupldbox input[type="file"] {
  font-size: 120px;
  opacity: 0;
  height: 100%;
  cursor: pointer !important;
}
.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}
.banner-ImageCard {
  position: absolute;
  z-index: 100;
  text-align: center;
}
.banner-tableDlt {
  width: 60px;
  height: 60px;
  background-color: red;
  border-radius: 0px 0px 100% 0px;
  position: absolute;
  padding: 12px;
}
.banner-tableConatiner {
  width: 50%;
  height: 200px;
  padding: 10px;
  position: relative;
}
.banner-tableImg {
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 8px 0px 100;
}
