.DashboardScreen-txt1 {
  font-family: "Montserrat-SemiBold";
  font-size: 25px;
}

.DashboardScreen-txt2 {
  color: gray;
}

.DashboardScreen-txt3 {
  font-family: "Montserrat-SemiBold";
  font-size: 14px;
  border: 1px solid rgb(210, 210, 210);
  height: 30px;
  padding: 0px 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.DashboardScreen-row {
  display: flex;
  flex-direction: row;
}

.DashboardScreen-item {
  border: 1px solid rgb(222, 222, 222);
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(240, 242, 245);
  cursor: pointer;
  border-radius: 4px;
}

.DashboardScreen-itemtxt1 {
  font-family: "Montserrat-SemiBold";
  font-size: 15px;
}

.DashboardScreen-itemtxt2 {
  font-family: "Montserrat-Regular";
  font-size: 12px;
  color: grey;
}
