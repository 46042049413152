.PageHeaders {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgb(232, 232, 232);
  padding-bottom: 3px;
  margin-top: -6px;
}

.PageHeaders-box1 {
  margin-right: 20px;
  font-size: 40px;
  margin-bottom: 5px;
  color: grey;
}

.PageHeaders-box2 {
  font-family: "Montserrat-SemiBold";
  font-size: 30px;
  color: #000;
}
.PageHeaders-txt1 {
  font-size: 12px;
  font-family: "Montserrat-Medium";
  color: gray;
  margin-top: -5px;
  margin-left: 2px;
}
.PageHeaders-txt2 {
  font-size: 12px;
  font-family: "Montserrat-Medium";
  color: gray;
}
.PageHeaders-children {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.PageHeaders-box5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.PageHeaders-txt3 {
  color: #0055a6;
  font-size: 16px;
  font-family: "Montserrat-SemiBold";
}
