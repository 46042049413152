.Article-table-NoImg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 6px;
  height: 50px;
  width: 70px;
  font-size: 8px;
}
