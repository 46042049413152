.inSnap-Main {
  height: 80vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  /* margin-bottom: 20px !important; */
  padding: 30px;
}

.inSnap-Scroll {
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 100%;
}
.insnap-delete {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  border-radius: 0px 0px 100% 0px;
  background-color: red;
  padding: 2px;
}
.inSnap-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
}
.inSnap-imgCard {
  width: 480px;
  height: 280px;
  padding: 20px;
  position: relative;
}
.inSnap-imgCardMain {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  width: 100%;
}
.inSnap-imgTitle {
  width: 200px;
  height: 100px;
  color: wheat;
}
.inSnap-imgCardLst {
  width: 100%;
  height: 320px;
}
.img-Upldr {
  width: 100px;
  height: 90px;
  background-color: rgb(238, 238, 238);
  border-radius: 8px;
  position: relative;
  display: grid;
  place-items: center;
  cursor: pointer;
  margin: 6px;
}
.inSnap-imgPlusBtn {
  width: 100px;
  height: 90px;
  background-color: rgb(238, 238, 238);
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  margin: 6px;
  font-family: "Montserrat-Medium";
  font-size: 12px;
}
.img-InputFile {
  position: absolute;
  top: 0;
  height: 90px;
  opacity: 0;
  cursor: pointer;
}
.inSnap-imgUpldrCard {
  display: flex;
  flex-wrap: wrap;
}
.inSnap-SbmtBttn {
  margin-bottom: 20px;
  width: 100%;
}
.inSnap-BttnRow {
  display: flex !important;
  justify-content: end !important;
}
.img-UpldrHead {
  width: 90%;
  height: 240px;
  background-color: rgb(238, 238, 238);
  border-radius: 8px;
  position: relative;
  display: grid;
  place-items: center;
  cursor: pointer;
  margin: 6px;
}
.inSnap-imgCard2 {
  max-width: 30%;
  height: 260px;
  margin: 10px;
}
.inSnap-title {
  font-family: "Noto_Sans_SemiBold_600";
  font-size: 22px;
  text-align: center;
}
.inSnaptable-Del {
  width: 70px;
  height: 70px;
  background-color: red;
  position: absolute;
  bottom: 190px;
  border-radius: 8px 0px 100%;
  padding: 12px;
}
.inSnapTable-Title {
  position: absolute;
  bottom: 0;
  padding: 20px;
  color: rgb(0, 0, 0);
  font-family: "Noto_Sans_SemiBold_600";
  font-size: 13px;
  /* display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden; */
}
.medialibraryInSnap-imgUpldr {
  width: 100%;
  height: 200px;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  position: relative;
  z-index: 10;
}
.medialibraryInSnap {
  width: 100%;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}
.PreviewCoverImage {
  width: 100%;
  height: 300px;
}
.PreviewCoverImage img {
  object-fit: fill;
  width: 100%;
  height: 300px;
}
.insnapPreviews {
  width: 100%;
  height: 75px;
  display: flex;
}
.insnapPreviewsImg {
  width: 30%;
  height: 100%;
  object-fit: fill;
  position: relative;
}
.insnapPreviewsImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.insnapPreviewsTxt {
  width: 70%;
  height: 100%;
}

.Insnap-table-NoImg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 6px;
  height: 50px;
  width: 70px;
  font-size: 8px;
}
