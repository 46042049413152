.profileScrn-box1 {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.profileScrn-Box2 {
  width: 30%;
  /* height: 100%; */
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 16px;
}
.profileScrn-Box5 {
  width: 65%;
  height: 425px;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.profileScrn-Box3 {
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c2c2c245;
}
.profileScrn-Txt1 {
  font-family: "Montserrat-SemiBold";
  font-size: 20px;
  color: grey;
  padding: 10px;
  border-bottom: 1px solid rgb(197, 197, 197);
}
.profileScrn-Txt2 {
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
}
.profileScrn-Txt3 {
  font-family: "Montserrat-Medium";
  font-size: 12px;
  margin-bottom: 3px;
  color: grey;
  margin-top: 16px;
}
.profileScrn-Txt4 {
  font-family: "Montserrat-SemiBold";
  color: rgb(0, 0, 0);
}
.profileScrn-Txt5 {
  font-family: "Montserrat-Bold";
  font-size: 14px;
  color: #979191;
  margin-left: 10px;
}
.profileScrn-Box4 {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #c2c2c245;
  border-radius: 4px;
  cursor: pointer;
  padding: 6px;
}
.profileScrn-Box6 {
  display: flex;
  justify-content: space-between;
  /* width: 124px; */
}
.editProfile-Box1 {
  display: flex;
  justify-content: center;
  position: relative;
}
.editProfile-Box2 {
  display: flex;
  justify-content: center;
}
.editProfile-Box2 {
  width: 160px;
  height: 160px;
  background-color: #80808029;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  object-fit: cover;
}
.editProfile-img {
  width: 70px;
  height: 70px;
}
.editPrfile-inputFile {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  position: absolute;
  opacity: 0;
}
.editProfile-Main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.editProfile-Container {
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  width: 80%;
  /* padding: 33px 0px 53px 0px; */
}
.editProfile-Bttn {
  width: 100%;
  height: 40px;
}
