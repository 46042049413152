.imagetext1{
    font-family: "Montserrat-Bold";
    font-size: 18px;
    color: #3f3f3f;
  }
  .imageuploadbox {
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border: 1px solid #d3d2d2;
      background-color: #ffffff;
      padding: 10px;
      border-radius: 0px;
      height: 100%;
      border-radius: 5px;
      font-family: "Montserrat-Regular";
      margin-top: 2px;
    }
    
    .imageuploadbox input[type="file"] {
      font-size: 100px;
      position: absolute;
      font-family: "Montserrat-SemiBold";
      left: 0;
      top: 0;
      opacity: 0;
      height: 100%;
    }
    .closebutton {
      background: rgb(231, 231, 231);
      color: rgb(88, 88, 88);
      margin-top: 10px;
      width: 100%;
      font-family: "Montserrat-SemiBold";
      text-align: center;
      padding: 10px;
      border: 0px;
      border-radius: 2px;
    }
    .submitbuttion {
    background: linear-gradient(to right, #3f70d1, #3d59ca, #1648ed);
    color: rgb(255, 255, 255);
    margin-top: 10px !important;
    text-align: center;
    padding: 10px;
    font-family: "Montserrat-SemiBold";
    border: 0px;
    border-radius: 2px;
    cursor: pointer;
  }
  .stop{
    background-color:#3f70d1;
    color: #ffff;
    width: auto !important;
  }