.filterText {
  font-family: "Montserrat-Medium";
  font-size: 13px;
  line-height: 16px;
  color: "red";
}
/* handle */
::-webkit-scrollbar {
  display: none;
  width: 1px;
  /* width of the vertical scrollbar */
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #c8c6c6;
  /* color of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #afaeae;
  /* color of the scrollbar thumb on hover */
}
.picFromImage {
  width: 50px;
  height: 35px;
  border-radius: 5px;
  object-position: center;
  object-fit: cover;
  margin-top: 5px;
  margin-bottom: 5px;
}
.pickerImage {
  cursor: pointer;
  width: 83%;
  height: 150px;
  border-radius: 6px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pickerAudioEditorial {
  cursor: pointer;
  width: 50%;
  height: 70px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headingIcon {
  display: flex;
  justify-content: flex-start;
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0055a6;
  margin-top: 8px;
}
.imagePIckerCard-Txt {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  font-weight: 700;
  color: Grey;
}
.editorailFrm-bttn {
  width: 100%;
  height: 50px;
}
.edtrlModal-ImgPicker {
  width: 100%;
  height: 192px;
  border-radius: 7px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.edtrlModl-ImageCard {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.dtrlModl-headTxt {
  font-family: "Montserrat-Semibold";
  font-size: 20px;
  font-weight: 700;
  color: #2d3f50;
  margin-bottom: 26px;
}
.edtrlMdl-dscrptnTxt {
  font-family: "Montserrat-Semibold";
  font-size: 16px;
  font-weight: 400;
}
