.imagePicker-GallaryHeader {
    display: flex;
    align-items: center;
}

.imagePicker-img-picker {
    width: 100%;
    height: 70vh;
}

.ImagePicker-backgroud {
    min-height: 400px;
    height: max-content;
    justify-content: center;
    align-items: center;
}

.no-img {
    background-color: lightgray;
    display: flex;
    height: 450px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 10px;
}

.Picker-Img {
    width: 85%;
    height: auto;
    object-fit: contain;

}