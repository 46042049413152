.reeslScreen-reel {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.reeslScreen-main-card {
  background-color: #f9f9f9;
  margin: 40px 10px;
  /* height: 100vh; */
  border-radius: 15px;
  padding: 20px;
}

.hover-overlay {
  /* background-color: red; */
  display: flex;
  height: 100%;
}

.overlay-item {
  height: 20px;
}

/* .like-item:hover {
    background-color: rgb(254, 64, 64);
    border-radius: 7px;
  } */
/* ///////////////////////////////////////////// */
.reelScrn-mainDiv {
}
.reelScrn-formLabel {
  font-family: "Montserrat-SemiBold" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #0055a6 !important;
  margin-bottom: 5px !important;
}
.reelScrn-Btn {
  width: 378px;
  height: 52px;
}
.reelList-ScndDiv {
  display: flex;
  flex-wrap: wrap;
  height: 65vh;
  overflow-x: scroll;
}
.reelScrn-HvrLayDiv1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.reelScrn-HvrLayDiv2 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reelScrn-hvrLayBtnsDiv {
  width: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  opacity: 0.6;
  padding-top: 5px;
  padding-bottom: 5px;
  opacity: 1;
}

.reelList-ScndDiv {
  display: flex;
  flex-wrap: wrap;
  height: 65vh;
  overflow-y: auto;
  margin-top: 26px;
}
.loading-overlay {
  width: 100%;
  height: 50px;
  background-color: white;
}
.reelScrn-scndDiv {
  display: flex;
  width: "200px";
  padding: 10px;
}
.reelScrn-Uploadtxt {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  font-weight: 700;
  margin-top: 20px;
  color: Grey;
}
.reelList-Video {
  object-fit: cover;
}
