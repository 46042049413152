.AddScreen {
  height: 100vh;
  overflow: scroll;
}
.addScreen-picker {
  border: 1px dashed rgb(86, 143, 173);
  background-color: rgb(242, 251, 255);
  border-radius: 6px;
  display: flex;
  position: relative;
  overflow: hidden;
  padding: 2px;
}

.addScreen-pickerBox {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.addScreen-pickertxt1{
  font-family: "Montserrat-SemiBold";
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
}

.addScreen-pickertxt2{
  font-family: "Montserrat-Regular";
  color: gray;
  font-size: 12px;
  text-align: center;
}

.addScreen-header {
  border: 1px solid rgb(241, 241, 241);
  border-radius: 6px;
  margin: 20px 0px;
  padding: 20px;
  background-color: rgba(234, 234, 234, 0.81);
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: "Montserrat-Bold";
}

.addScreen-box {
  border: 1px solid rgb(235, 235, 235);
  border-radius: 6px;
  padding: 20px;
  background-color: rgba(234, 234, 234, 0.81);
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: "Montserrat-Bold";
}

.addScreen-Detaild {
  border: 1px solid rgb(235, 235, 235);
  border-radius: 6px;
  margin: 20px 0px;
  padding: 20px;
  background-color: rgb(227, 227, 227);
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.addScreen-Related {
  border: 1px solid rgb(235, 235, 235);
  border-radius: 6px;
  margin: 20px 0px;
  padding: 20px;
  background-color: rgb(227, 227, 227);
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.addScreen-ImageUploaderBox1 {
  height: 30vh;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addScreen-ImageUploaderImg1{
  width: 100%;
  height: 100%;
  border-radius: 5px;
  max-height: 150px;
}
.addScreen-ImageUploaderBox2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 12px;
  height: 47vh;
  color: gray;
}

.addScreen-homeImage1{
  width: 100%;
  object-fit: cover;
}
.addScreen-pickerIcon{
  display: flex;
  align-items: center;
justify-content: flex-end;
padding-right: 1px;
}
.addScreen-pickerIcon2{
  padding: 5px 15px 7px 30px;
  border-radius: 0px 0px 0px 200px;
  border: 1px dashed rgb(86, 143, 173);
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 13px;
  font-family: "Montserrat-SemiBold";
}

.addScreen-lineHor{
  background-color: rgb(86, 143, 173);
  width: 1px;
  height: 20px;
}