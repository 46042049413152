.userfrom-box {
  border-left: 1px solid rgb(222, 222, 222);
  height: 100%;
  padding-left: 20px;
}

.userfrom-roles-item {
  padding: 10px 0px 10px 0px;
  border-top: 0.1px solid rgb(236, 236, 236);
}
