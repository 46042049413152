.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.poll-headTxt {
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
  color: #9ba9b6;
}
.pollScreen-box1 {
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}
.poll-Result-box1 {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.poll-Result-txt1 {
  font-family: "Montserrat-SemiBold";
}
.poll-Result-txt2 {
  font-family: "Montserrat-Medium";
  margin-right: 10px;
}
