.NjayarArticles-Box1 {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.NjayarArticles-table-NoImg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 6px;
  height: 50px;
  width: 70px;
  font-size: 8px;
}
