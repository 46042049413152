.quiz-BannerImage {
  background-color: rgb(238, 238, 238);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  aspect-ratio: 1.6/1;
  border: 1px dashed rgb(208, 208, 208);
  font-size: 14px;
}

.quiz-BannerImage2 {
  background-color: rgb(231, 231, 231);
  border-radius: 4px;
  padding: 10px;
  aspect-ratio: 1.6/1;
}

.quiz-BannerImage3 {
  width: 100%;
  object-fit: contain;
  aspect-ratio: 1.5/1;
  border-bottom: 1px solid rgb(195, 195, 195);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.quiz-BannerImage4 {
  display: flex;
  gap: 10px;
  width: 100%;
}

.quiz-txt1 {
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  align-items: end;
  font-size: 12px;
}

.quiz-formtable {
  width: 100%;
}

.quiz-td1 {
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
}

.quiz-td2 {
  border: 1px solid rgb(213, 213, 213);
  width: 100%;
}

.quiz-td3 {
  border: 1px solid rgb(213, 213, 213);
}

.quiz-td4 {
  border: 1px solid rgb(213, 213, 213);
  padding: 5px 10px;
}

.SelectModal-Box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SelectModal-wheel1 {
  width: 300px;
}

.SelectModal-wheel2 {
  width: 300px;
  animation: rotateWheel 1s linear infinite;
}

.Quiz-tableItem {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

@keyframes rotateWheel {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
